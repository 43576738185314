.ColorlessAppBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 5vh;
    margin-top: 6vh;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

.NextButton {
    padding: 0px !important;
    margin-right: 20px !important;
}

.LawHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

#LawType {
    text-align: center;
    font-size: large;
    width: 100%;
    height: auto;
}

.LawGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}
