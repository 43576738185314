.VoteMenu {
    margin-top: 11vh;
}

.formControl {
    min-width: 75% !important;
}

.candidateSelection {
    text-align: center;
}

.buttonArea {
    bottom: 0;
    margin-top: 50px;
    align-items: center;
    text-align: center;
}

.drawingArea {
    margin-top: 30px;
    margin-left: 17%;
}

.drawing {
    max-width: 128px;
    max-height: 256px;
}
