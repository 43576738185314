.paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin: 4px;
}

form {
    width: 100%;
    margin-top: 10px;
}

.submit {
    margin: 3px 0 2px;
}