.VoteBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.VoteHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

.VoteGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

#overviewButton {
    margin-top: -65px;
    width: 30px;
    height: 30px;
    padding-right: 50px;
}
