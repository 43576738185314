.MovieBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.MovieOverviewHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

.MovieGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.SortButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

#sortIcon {
    height: 35px;
    width: 35px;
}

#sortMovieContent {
    display: inherit;
}
