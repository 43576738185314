.paper {
    position: relative;
}

.CloseAddMenu {
    position: fixed;
    top: 40px;
    right: 0;
    font-size: large;
}

#CloseButton {
    color: red;
    width: 35px;
    height: 35px;
}

.ChangeButtonContainer {
    margin: auto !important;
    width: 50%;
    padding: 10px;
}

.ChangeRatingButton {
    height: 25px;
    width: 25px;
}

.MovieThumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}
