.itemList {
    width: available;
}

.fullList {
    margin-top: 4vh;
    width: available;
}

.MuiDrawer-paperAnchorLeft {
    margin-top: 7vh;
    width: 75%;
}

.singleItem {
    height: 15vh;
}

.MenuButton {
    padding: 0px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.MenuIconButton {
    color: white;
    margin-left: -10px;
}

#help-icon {
    display: flex;
    height: 100%;
}

#help-icon svg {
    position: absolute;
    bottom: 65px;
    left: 10px;
}