p {
    font-size: x-large;
    text-align: center;
}

.MainMenuLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.mmLogo {
    width: 300px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
}

@media (max-width: 400px), (max-height: 600px) {
    .MainMenuLogo {
        display: contents;
    }
}

@media (max-width: 500px), (max-height: 700px) {
    .spacer {
        display: none;
    }
}

.spacer {
    height: 12.5vh;
}

#mainContainer {
    margin-top: 10vh;
}

@media (max-height: 700px) {
    #membersOverview {
        line-height: normal;
    }
}
