@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap);
.paper {
    margin-top: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.avatar {
    margin: 4px;
}

form {
    width: 100%;
    margin-top: 10px;
}

.submit {
    margin: 3px 0 2px;
}
.itemList {
    width: available;
}

.fullList {
    margin-top: 4vh;
    width: available;
}

.MuiDrawer-paperAnchorLeft {
    margin-top: 7vh;
    width: 75%;
}

.singleItem {
    height: 15vh;
}

.MenuButton {
    padding: 0px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.MenuIconButton {
    color: white;
    margin-left: -10px;
}

#help-icon {
    display: -webkit-flex;
    display: flex;
    height: 100%;
}

#help-icon svg {
    position: absolute;
    bottom: 65px;
    left: 10px;
}
.menuButton {
    margin-right: 2px;
}

@media (min-width: 0px) and (orientation: landscape) {
    .MuiToolbar-regular {
        min-height: 0px;
    }
}

.MuiToolbar-regular {
    min-height: 0px !important;
}

.MuiGrid-root {
    height: 100%;
    width: 100%;
}

#mainHeader {
    height: 6vh;
}

#logInButton {
    padding: 0;
}
#signOutButton svg {
    width: 30px !important;
}
p {
    font-size: x-large;
    text-align: center;
}

.MainMenuLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.mmLogo {
    width: 300px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
}

@media (max-width: 400px), (max-height: 600px) {
    .MainMenuLogo {
        display: contents;
    }
}

@media (max-width: 500px), (max-height: 700px) {
    .spacer {
        display: none;
    }
}

.spacer {
    height: 12.5vh;
}

#mainContainer {
    margin-top: 10vh;
}

@media (max-height: 700px) {
    #membersOverview {
        line-height: normal;
    }
}

.MovieList {
    position: relative;
}

.AddButtonDiv {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: large;
}

#CircleButton {
    width: 75px;
    height: 75px;
}

.MovieObject ~ .MovieObject {
    border-top: 1px inset white;
}

.MovieObject {

    padding-top: 7px;
    padding-bottom: 7px;
}
.MovieBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.MovieOverviewHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

.MovieGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.SortButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

#sortIcon {
    height: 35px;
    width: 35px;
}

#sortMovieContent {
    display: inherit;
}

span {
    font-size: 1.4em !important;
}
.ColorlessAppBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 5vh;
    margin-top: 6vh;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

.NextButton {
    padding: 0px !important;
    margin-right: 20px !important;
}

.LawHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

#LawType {
    text-align: center;
    font-size: large;
    width: 100%;
    height: auto;
}

.LawGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.ColorlessAppBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

.MovieHeader {
    font-size: x-large;
    padding-top: 5px !important;
}

#MovieName {
    text-align: center;
    font-size: large;
    width: 100%;
    height: auto;
    font-size: x-large;
}

.MovieNameField {
    width: 100%;
}

.MovieRating {
    text-align: center;
    outline: white solid 1px;
    width: auto;
    height: auto;
}

.MovieGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.MovieThumbnail {
    min-height: 20vh;
    min-width: 40%;
    max-height: 40vh;
    max-width: 80%;
}

.InspectionContent {
    margin-top: 3vh;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50%;
}

.IndividualRatings {
    font-size: x-large;
}

#dateField {
    font-size: x-large !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: center;
}

.inspectionItem {
    padding-top: 10px;
}

.editIcon {
    color: white;
    position: absolute !important;
    bottom: 10px;
    right: 10px;
}

#editIcon {
    width: 30px;
    height: 30px;
}

.rateIcon {
    color: white;
    position: absolute !important;
    bottom: 10px;
    left: 10px;
}

#rateIcon {
    width: 30px;
    height: 30px;
}

#ActiveEditing {
    color: green !important;
}

.paper {
    position: relative;
}

.CloseAddMenu {
    position: fixed;
    top: 40px;
    right: 0;
    font-size: large;
}

#CloseButton {
    color: red;
    width: 35px;
    height: 35px;
}

.ChangeButtonContainer {
    margin: auto !important;
    width: 50%;
    padding: 10px;
}

.ChangeRatingButton {
    height: 25px;
    width: 25px;
}

.MovieThumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.VoteBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.VoteHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

.VoteGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

#overviewButton {
    margin-top: -65px;
    width: 30px;
    height: 30px;
    padding-right: 50px;
}

.VoteMenu {
    margin-top: 11vh;
}

.formControl {
    min-width: 75% !important;
}

.candidateSelection {
    text-align: center;
}

.buttonArea {
    bottom: 0;
    margin-top: 50px;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.drawingArea {
    margin-top: 30px;
    margin-left: 17%;
}

.drawing {
    max-width: 128px;
    max-height: 256px;
}

.OverviewBar {
    height: 6vh;
    margin-top: 6vh;
}

.OverviewHeader {
    font-size: x-large;
    padding-top: 5px !important;
    text-align: center;
}

.OverviewGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}

.OverviewBackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

.HistoryGridItem {
    text-align: center;
}

.votingHistoryObject ~ .votingHistoryObject {
    border-top: 1px inset white;
    margin-top: 5px;
}
.Logo {
    width: 300px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.8);
}

