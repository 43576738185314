.ColorlessAppBar {
    /* background-color: rgba(1, 1, 1, 0) !important; */
    height: 6vh;
    margin-top: 6vh;
}

.BackButton {
    padding: 0px !important;
    margin-left: 20px !important;
}

.MovieHeader {
    font-size: x-large;
    padding-top: 5px !important;
}

#MovieName {
    text-align: center;
    font-size: large;
    width: 100%;
    height: auto;
    font-size: x-large;
}

.MovieNameField {
    width: 100%;
}

.MovieRating {
    text-align: center;
    outline: white solid 1px;
    width: auto;
    height: auto;
}

.MovieGridItem {
    padding: 0px !important;
    height: auto;
    width: 100%;
}
